.App {
  text-align: center;
  background-color: #762E2E;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 2rem;
}

.App-link {
  color: #61dafb;
}

.play-store-button {
  display: inline-block;
  background-color: #018786; /* Secondary color */
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.mini-main-manual-button {
  display: inline-block;
  background-color: #018786; /* Secondary color */
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.mini-manual-button {
  display: inline-block;
  background-color: #018786; /* Secondary color */
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  margin: 1rem;
}

.manual-history {
  color: white;
}

.icon {
  margin-bottom: 2rem;
}

.play-store-button:hover {
  background-color: #03DAC5; /* Darker shade for hover effect */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
